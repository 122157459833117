import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  Inject,
  model,
  type OnInit,
  signal
} from '@angular/core';
import {IBasket, IPackagePriceDto, IUpdateBasketItemResponse} from '@GeneratedTsFiles/index';
import {ButtonModule} from 'primeng/button';
import {CustomDialogPopupComponent} from '../../custom-dialog-popup/custom-dialog-popup.component';
import {
  PackageExtensionCardComponent
} from 'src/app/features/buy-package/buy-package-extension-selection/package-extension-card/package-extension-card.component';
import {EmitEvent, EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {ApiService} from 'src/app/core/services/api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-buy-package-addon-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CustomDialogPopupComponent,
    PackageExtensionCardComponent,
  ],
  templateUrl: './buy-package-addon-dialog.component.html',
  styleUrl: './buy-package-addon-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyPackageAddonDialogComponent implements OnInit {
  eventBusService = inject(EventBusService);
  authService = inject(AuthService);
  apiService = inject(ApiService);
  dialogVisible = signal(true);
  dialogStyleClass = signal('p-dialog p-dialog-md purple-dialog-header');
  dialogStyle = signal({width: '40vw'});
  dialogHeader = computed(() => `Buy Included Text`);
  dialogStyle$ = computed(() => this.dialogStyle());
  extensionSelected = model(false);
  parameters: any;
  pricePackage = signal({} as IPackagePriceDto);
  private readonly destroy: DestroyRef = inject(DestroyRef);


  constructor(@Inject('dialogParameters') parameters: any) {
    this.parameters = (parameters);
  }

  ngOnInit(): void {
    console.log(this.parameters);
    this.dialogStyleClass.set('p-dialog p-dialog-sm purple-dialog-header');
    this.dialogStyle.set({width: '810px'});
    this.pricePackage.set(this.parameters.pricePackage);
    this.extensionSelected.set(this.parameters.basketItem.hasExtension);
  }

  closeDialog() {
    // this.reloadBasketEvent();
    // this.updateBasketItemExtension();
    this.dialogVisible.set(false);
  }

  updateBasketItemAndClose() {
    this.updateBasketItemExtension(this.parameters.basketItem.id);
    this.closeDialog();
  }

  private updateBasketItemExtension(basketItemId: string): void {
    this.apiService.getApiData<IUpdateBasketItemResponse>(
      {url: IBasket.patchUpdateBasketItem, method: 'PATCH'},
      {
        quantity: 0,
        basketItemId: basketItemId,
        hasExtension: this.extensionSelected()
      }).pipe(takeUntilDestroyed(this.destroy)).subscribe({
      next: (response: IUpdateBasketItemResponse) => {
        console.log(response);
        this.reloadBasketEvent();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  private reloadBasketEvent() {
    this.eventBusService.emit(new EmitEvent(Events.StateLoadGetBasket,
      {
        parentId: this.authService.getUser()?.id
      }
    ));
  }

}
