@if (productViewType() === 'default') {


<div class="flex align-items-start py-3 px-2  flex-wrap w-full border-bottom-1 border-300">
    <div class="col-12 md:col-4">
        <div class=" font-bold">
         {{services.checkout.buildBasketItemTitle(basketItem) }}

            <span *ngIf="basketItem.isGroup" class="p-tag bg-indigo-500">Group</span>

        </div>
    </div>
  @if (showActions) {
    <div class="hidden md:block col-4 md:col-2">
        <ng-container [ngTemplateOutlet]="quantitySelectorTpl"
            [ngTemplateOutletContext]="{ $implicit: basketItem, cssClass: ' justify-content-center align-items-center'}"></ng-container>
    </div>
  }
    <div class="col-8 md:col-3">

        <!-- @let targetStudents = basketItem.targetStudents ?? []; -->
        <!-- {{targetStudents | json}} -->
        @if (targetStudents) {
        @for (targetStudent of targetStudents; track targetStudent) {
        {{ targetStudent.firstName }} {{ targetStudent.lastName }}
        <ng-container
            *ngIf="basketItem.targetStudents!.length > 1 && targetStudent !== basketItem.targetStudents![basketItem.targetStudents!.length - 1]">,
        </ng-container>
        }
        }

    </div>

    <div class="col-4 md:col-2">
        <div class=" flex flex-column align-items-end justify-content-end ml-auto">
            <span class="font-bold text-lg">{{basketItem.totalPrice}} €</span>

        </div>
    </div>
    <div class="col-12 md:col-1">

        <div class="hidden md:flex flex-column align-items-center justify-content-center">

            <ng-container [ngTemplateOutlet]="deleteBasketItemIconTpl"
                [ngTemplateOutletContext]="{ $implicit: basketItem}"></ng-container>
        </div>
    </div>

    <div class="flex align-items-start   flex-wrap w-full" *ngIf="basketItem.hasExtension">
        <div class="col-8 md:col-8 pt-0">

            <p class="m-0 p-0">+ Extension (One Month)</p>
            <p class="m-0 p-0 font-light" *ngIf="basketItem.packOfLessonsDiscountPrice">+ Group Discount</p>
        </div>


        <div class="col-4 md:col-3 pt-0">
            <div class=" flex flex-column align-items-end justify-content-end ml-auto">

                <span> + {{basketItem.extensionPrice}} €</span>
                <span *ngIf="basketItem.packOfLessonsDiscountPrice"> - {{basketItem.packOfLessonsDiscountPrice}}
                    €</span>
            </div>
        </div>

        <div class="col-12 md:col-1 pt-0 hidden md:block">
            &nbsp;
        </div>
    </div>

    <p-button (click)="openAddonDialog(basketItem)" class="button_addon" label="Add-on" icon="pi pi-plus"
        [rounded]="true" [outlined]="true" size="small" />

  @if (showActions) {
    <div class="flex sm:hidden mt-3 align-items-center justify-content-between w-full">
        <div class="flex sm:hidden">
            <ng-container [ngTemplateOutlet]="quantitySelectorTpl"
                [ngTemplateOutletContext]="{ $implicit: basketItem}"></ng-container>
        </div>


        <div class="flex md:hidden flex-column align-items-center justify-content-center">

            <ng-container [ngTemplateOutlet]="deleteBasketItemIconTpl"
                [ngTemplateOutletContext]="{ $implicit: basketItem}"></ng-container>
        </div>
    </div>
  }
</div>
} @else {
<li
    class="py-3 flex md:align-items-start md:justify-content-between flex-row primary-purple-color border-bottom-1 border-100">
    <div class="flex align-items-start mr-0 lg:mr-2">
        <div><span class=" block mb-2 text-sm font-semibold line-height-3">
                {{basketItem.quantity}} x {{services.checkout.buildBasketItemTitle(basketItem) }}

                <p class="m-0 p-0 flex align-items-center gap-2 font-light">
                    <span *ngIf="basketItem.isGroup" class="p-tag bg-indigo-500">Group</span>
                    <span>
                        @if (targetStudents) {
                        @for (targetStudent of targetStudents ?? []; track targetStudent) {
                        {{ targetStudent.firstName }} {{ targetStudent.lastName }}
                        <ng-container
                            *ngIf="basketItem.targetStudents!.length > 1 && targetStudent !== basketItem.targetStudents![basketItem.targetStudents!.length - 1]">,
                        </ng-container>
                        }
                        }
                    </span>
                </p>
                <p class="m-0 p-0" *ngIf="basketItem.hasExtension">+ Extension (One Month)
                    <span>{{basketItem.extensionPrice}}
                        €</span>
                </p>


            </span>
            <div class="font-light text-sm mb-2">
                <!-- @for (targetStudent of basketItem.targetStudents; track targetStudent) {
              {{ targetStudent.firstName }} {{ targetStudent.lastName }}
              <ng-container
                *ngIf="basketItem.targetStudents.length > 1 && targetStudent !== basketItem.targetStudents[basketItem.targetStudents.length - 1]">,
              </ng-container>
              } -->
            </div>
            <div class="text-lg font-semibold">{{basketItem.totalPrice}} €</div>

          @if (showActions) {
            <div class="flex my-2">
                <ng-container [ngTemplateOutlet]="quantitySelectorTpl"
                    [ngTemplateOutletContext]="{ $implicit: basketItem, cssClass: 'justify-content-start'}"></ng-container>

                <div class="text-sm font-semibold mt-1">
                    <p-button (click)="openAddonDialog(basketItem)" class="button_addon" label="Add-on"
                        icon="pi pi-plus" [rounded]="true" [outlined]="true" size="small" />

                </div>
            </div>
          }


        </div>
    </div><span class="block font-medium ml-auto sm:ml-7 md:ml-5 mt-2 md:mt-0">
        @if (sidebarShowDeleteCartItemsButton() && showActions) {
        <p-button (click)="confirmRemoveItemFromBasket(basketItem)" icon="pi pi-trash" size="small" [outlined]="true"
            styleClass="p-0 w-2rem h-2rem" severity="danger" />
        }

    </span>
</li>
}

<ng-template #quantitySelectorTpl let-basketItem let-cssClass="cssClass">

    <div class="flex flex-auto" [ngClass]="cssClass">
        <button class="p-button q-btn text-sm hover:text-primary text-center" [disabled]="disabledQuantityButtons()"
            (click)="decrement(basketItem)">
            <i class="pi pi-minus"></i>
        </button>
        <span class="w-2rem text-center text-sm py-1 px-1" [innerHTML]="basketItem.quantity"></span>
        <button class="p-button q-btn text-sm hover:text-primary" [disabled]="disabledQuantityButtons()"
            (click)="increment(basketItem)">
            <i class="pi pi-plus"></i>
        </button>
    </div>
</ng-template>

<ng-template #deleteBasketItemIconTpl let-basketItem>
    <p-button (click)="confirmRemoveItemFromBasket(basketItem)" icon="pi pi-trash" size="small"
        styleClass="p-0 w-2rem h-2rem remove-btn" severity="danger" />
</ng-template>
