<app-custom-dialog-popup [header]="'Title'" [maximizable]="false" [styleClass]="dialogStyleClass()"
                         [style]="dialogStyle$()" [visible]="dialogVisible()">
  <ng-container dialogHeader>
    <div
      class=" w-full inline-flex align-items-center text-white font-semibold justify-content-center gap-2 fluid-title">
      Choose an add-on (Optional)
    </div>
    <!-- Header content here -->
  </ng-container>

  <div class="p-2 sm:p-4 flex align-items-center justify-content-center">

    <app-package-extension-card [(extensionSelected)]="extensionSelected"></app-package-extension-card>
  </div>

  <div class="button-actions mt-4 sm:mt-2 flex align-items-center justify-content-center">

    <button (click)="updateBasketItemAndClose()" class="cursor-pointer text-lg azure-bg-btn p-button-rounded py-2 flex align-items-center justify-content-center text-center w-full sm:w-auto mb-2 sm:mr-3" pButton pRipple
            type="submit">
            <span class="flex align-items-center justify-content-center gap-2 ">
                Update
            </span>
    </button>
  </div>


</app-custom-dialog-popup>
